import { isEmptyArray } from 'formik';
import * as React from 'react';
import { ActiveSelectInput } from '../../components/optimaiComponents/active-select-input';
// import '../css/style.css';

export const ServeyStepTwo = ({ serveyObject, setServeyObject, setCurrentStep, currentStep }) => {
  const [questionTwo, setQuestionTwo] = React.useState(false);
  const [questionThree, setQuestionThree] = React.useState(false);
  const [nextButton, setNextButton] = React.useState(false);
  const warningLifeSecs = 5000;
  const [answeredQuestions, setAnsweredQuestions] = React.useState(0);
  const [localServeyObject, setLocalServeyObject] = React.useState(0);
  const [localLoanUrgency, setLocalLoanUrgency] = React.useState(0);
  const loanUrgencyList = [
    { name: 'בירור ראשוני', value: '1' },
    { name: 'מחפש נכס', value: '2' },
    { name: 'נחתם חוזה', value: '3' },
    { name: 'שולם חלק מהסכום', value: '4' },
    { name: 'יש נכס', value: '5' },
    { name: 'אחר', value: '6' }
  ];
  const [localPropertyType, setLocalPropertyType] = React.useState(0);
  const propertyTypeList = [
    { name: 'דירה במחיר למשתכן', value: '1' },
    { name: 'דירה מקבלן - על הנייר', value: '2' },
    { name: 'דירה מקבלן - בנויה', value: '3' },
    { name: 'דירת מגורים (לא מקבלן)', value: '4' },
    { name: 'מגרש לבנייה עצמית', value: '5' }
  ];
  const [localPropertyAddress, setLocalPropertyAddress] = React.useState(0);
  const propertyAddressList = [
    { name: 'מרכז', value: '1' },
    { name: 'צפון', value: '2' },
    { name: 'דרום', value: '3' }
  ];

  const saveServeyResult = () => {
    let newServeyObject = {
      ...serveyObject,
      ...{
        loanUrgency: localLoanUrgency,
        propertyType: localPropertyType,
        propertyAddress: localPropertyAddress
      }
    };
    setServeyObject(newServeyObject);
    localStorage.setItem('serveyObject', JSON.stringify(newServeyObject));
    setCurrentStep(currentStep + 1);
  };

  const prevStep = () => {
    let newServeyObject = {
      ...serveyObject,
      ...{
        loanUrgency: localLoanUrgency,
        propertyType: localPropertyType,
        propertyAddress: localPropertyAddress
      }
    };
    setServeyObject(newServeyObject);
    localStorage.setItem('serveyObject', JSON.stringify(newServeyObject));
    setCurrentStep(currentStep - 1);
  };

  const IsEmpty = (value) => {
    const m = value === 0 || value === '' || value === null || value === undefined;
    return m;
  };

  const NotEmpty = (value) => {
    const m = value !== 0 && value !== '' && value !== null && value !== undefined;
    return m;
  };

  React.useEffect(() => {
    window.scrollTo({ top: 0 });

    console.log('serveyObject: ', serveyObject);

    setLocalLoanUrgency(serveyObject['loanUrgency']);
    setLocalPropertyType(serveyObject['propertyType']);
    setLocalPropertyAddress(serveyObject['propertyAddress']);

    if (NotEmpty(serveyObject['loanUrgency'])) {
      const loanUrgency = document.querySelector("#loanUrgency .selectbox__text");
      loanUrgency.innerHTML = serveyObject['loanUrgency'].name;
    }
    
    if (NotEmpty(serveyObject['propertyType'])) {
      const propertyType = document.querySelector("#propertyType .selectbox__text");
      propertyType.innerHTML = serveyObject['propertyType'].name;
    }
    
    if (NotEmpty(serveyObject['propertyAddress'])) {
      const propertyAddress = document.querySelector("#propertyAddress .selectbox__text");
      propertyAddress.innerHTML = serveyObject['propertyAddress'].name;
    }
  }, []);

  const readInputValue = (identificator) => {
    const localServeyObjectSave = {};
    const inputValue = document.getElementById(identificator).value;
    localServeyObjectSave[identificator] = inputValue;
    let newLocalServeyObject = {
      ...localServeyObject,
      ...localServeyObjectSave
    };
    setLocalServeyObject(newLocalServeyObject);
  };

  React.useEffect(() => {
    if (answeredQuestions === 'openQuestionTwo') {
      setQuestionTwo(true);
      setQuestionThree(false);
      setNextButton(false);
    } else if (answeredQuestions === 'openQuestionThree') {
      setQuestionTwo(true);
      setQuestionThree(true);
      setNextButton(false);
    } else if (answeredQuestions === 'openNextButton') {
      setQuestionTwo(true);
      setQuestionThree(true);
      setNextButton(true);
    }
  }, [answeredQuestions]);

  React.useEffect(() => {
    if (NotEmpty(localLoanUrgency)) {
      setAnsweredQuestions('openQuestionTwo');
    }
    
    if (NotEmpty(localPropertyType) && NotEmpty(localLoanUrgency)) {
      setAnsweredQuestions('openQuestionThree');
    }

    if (NotEmpty(localPropertyAddress) && NotEmpty(localPropertyType) && NotEmpty(localLoanUrgency)) {
      setAnsweredQuestions('openNextButton');
    }
  }, [localLoanUrgency, localPropertyType, localPropertyAddress]);

  return (
    <>
      <div class="home-page__bg-1">
        <img src="img/home-page-bg-1.png" alt="" />
      </div>
      <div class="container">
        <div class="step">
          <div class="step__item">
            <div class="step__inner">
              4
            </div>
          </div>
          <div class="step__item">
            <div class="step__inner">
              3
            </div>
          </div>
          <div class="step__item">
            <div class="step__inner">
              2
            </div>
          </div>
          <div class="step__item">
            <div class="step__inner step__inner--active">
              1
            </div>
          </div>
        </div>
        <div class="home-page__wrapper">
          <div class="home-page__form hp-form">
            <div class="hp-form__row">
              <div class="hp-form__item">
                <div class="hp-form__title">
                  <span>מיקום הנכס</span>
                </div>
                <div class="hp-form__field">
                  <ActiveSelectInput selectOptions={propertyAddressList} value={localPropertyAddress} setValue={setLocalPropertyAddress} disabled={!questionThree} EmptyMassage="question-three-prev-empty" selectId="propertyAddress" prevSelectId="propertyType" />
                  <div class="form-message none" Id="question-three-prev-empty">
                    <div class="form-message__text">חסר נתון קודם</div>
                    <div class="form-message__icon"><img src="img/STOP-ICON.png" alt="" /></div>
                  </div>
                  {IsEmpty(localPropertyAddress) && questionThree ? (
                    <div class="form-message">
                      <div class="form-message__text">
                        באיזה איזור ממוקם הנכס?
                      </div>
                      <div class="form-message__icon"><img src="img/QUES-ICON.png" alt="" /></div>
                    </div>
                  ) : ''}
                  {NotEmpty(localPropertyAddress) && questionThree ? (
                    <div class="form-message">
                      <div class="form-message__text">
                        מעולה. לחץ על הבא כדי להמשיך.
                      </div>
                      <div class="form-message__icon"><img src="img/OK-ICON.png" alt="" /></div>
                    </div>
                  ) : ''}
                </div>
              </div>
              <div class="hp-form__item">
                <div class="hp-form__title">
                  <span>סוג העסקה</span>
                </div>
                <div class="hp-form__field">
                  <ActiveSelectInput selectOptions={propertyTypeList} value={localPropertyType} setValue={setLocalPropertyType} disabled={!questionTwo} EmptyMassage="question-two-prev-empty" selectId="propertyType" prevSelectId="loanUrgency" />
                  <div class="form-message none" Id="question-two-prev-empty">
                    <div class="form-message__text">חסר נתון קודם</div>
                    <div class="form-message__icon"><img src="img/STOP-ICON.png" alt="" /></div>
                  </div>
                  {IsEmpty(localPropertyType) && questionTwo ? (
                    <div class="form-message">
                      <div class="form-message__text">
                        דירה פירושה כל סוג של נכס מגורים.
                      </div>
                      <div class="form-message__icon"><img src="img/QUES-ICON.png" alt="" /></div>
                    </div>
                  ) : ''}
                  {NotEmpty(localPropertyType) && questionTwo ? (
                    <div class="form-message">
                      <div class="form-message__text">
                        יופי, עוברים לבחירת מיקום הנכס.
                      </div>
                      <div class="form-message__icon"><img src="img/OK-ICON.png" alt="" /></div>
                    </div>
                  ) : ''}
                </div>
              </div>
              <div class="hp-form__item">
                <div class="hp-form__title">
                  <span>שלב בתהליך</span>
                </div>
                <div class="hp-form__field">
                  <ActiveSelectInput selectOptions={loanUrgencyList} value={localLoanUrgency} setValue={setLocalLoanUrgency} selectId="loanUrgency" EmptyMassage={null} />
                  {IsEmpty(localLoanUrgency) ? (
                    <div class="form-message">
                      <div class="form-message__text">
                        באיזה שלב אתה נמצא?
                      </div>
                      <div class="form-message__icon"><img src="img/QUES-ICON.png" alt="" /></div>
                    </div>
                  ) : (
                    <div class="form-message">
                      <div class="form-message__text">
                        מעולה. המשך לבחירת סוג העסקה.
                      </div>
                      <div class="form-message__icon"><img src="img/OK-ICON.png" alt="" /></div>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div class="hp-form__buttons">
              <button type="button" class="button active" onClick={prevStep}>הקודם</button>
              <button type="button" class="button next-button" onClick={() => saveServeyResult()} disabled={!nextButton}>הבא</button>
            </div>
          </div>
        </div>
      </div>
      <div class="home-page__bg-2">
        <img src="img/home-page-bg-2.1.png" alt="" class="hidden-iphone" />
        <img src="img/home-page-bg-2.11-mobile.png" alt="" class="visible-iphone" />
      </div>
    </>
  );
};
