import { Navigate } from 'react-router-dom';
import { Layout } from './components/layout';
import { Icons } from './pages/icons';
import { NotFound } from './pages/not-found';
import { Campaigns } from './pages/campaigns';
import { Reports } from './pages/reports';
import { Settings } from './pages/settings';
import { Theme } from './pages/theme';
import { Granularity } from './pages/granularity';
import { Content } from './pages/content';
import { Sections } from './pages/sections';
import { Rating } from './pages/rating';
import { Results } from './pages/results';
import { Lead } from './pages/lead';
import { Homepage } from './pages/homepage';
import { ContactUs } from './pages/contact-us';
import { Team } from './pages/team';
import { Services } from './pages/services';

export const routes = [
  {
    path: '/',
    element: <Homepage /> // calculator
  },
  {
    path: 'rating/:id',
    element: <Rating />
  },
  {
    path: 'calculator',
    element: <Reports />
  },
  {
    path: 'results/:id',
    element: <Results />
  },
  {
    path: 'lead/:id',
    element: <Lead />
  },
  {
    path: 'contact-us',
    element: <ContactUs />
  },
  {
    path: 'team',
    element: <Team />
  },
  {
    path: 'services',
    element: <Services />
  }
];
