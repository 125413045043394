import * as React from 'react';
import axios from 'axios';
import { Helmet } from 'react-helmet';
import { useParams } from 'react-router-dom';
import { Get, Post, Serialize } from '../services/http';
import { LinkCss } from '../services/cssService';

export const Rating = () => {
	const [rotationAngle, setRotationAngle] = React.useState('');
	const [cffResult, setCffResult] = React.useState('');
	const [score, setScore] = React.useState('');
	const { id } = useParams();
	const [loadingAnimation, setLoadingAnimation] = React.useState(false);
	const [desktopVideo, setDesktopVideo] = React.useState(false);
	const [animationRatingNum, setAnimationRatingNum] = React.useState(0);
	const [serveyCount, setServeyCount] = React.useState(0);
	const [optimaiPopup, setOptimaiPopup] = React.useState(null);

	React.useEffect(() => {
		LinkCss("/css/style.css");
	}, []);

	const flipString = (value) => {
		let output = [];

		if (value.length === 1) {
			return value;
		}

		for (let i = value.length; i >= 0; i--) {
			output.push(value[i]);
		}

		return output.join('');
	};

	const mask = (num) => {
		let output = [];

		num = num.toString();

		for (let i = 0; i < num.length; i++) {
			if (i === 0 && num[0] === '0') {
				output.push('');
			} else {
				output.push(num[i]);
			}
		}

		num = output.join('');
		output = [];
		let value = flipString(num);

		for (let i = 0; i < value.length; i++) {
			if (i !== 0 && i % 3 === 0) {
				output.push(',');
			}

			if (i !== value.length) {
				output.push(value[i]);
			}
		}

		value = output.join('');

		return flipString(value);
	};

	const initAccordion = () => {
		const boxes = document.querySelectorAll('.spoller__head');

		console.log('boxes', boxes);

		for (const box of boxes) {
			box.addEventListener('click', function handleClick() {
				const parents = document.querySelectorAll('.spoller__item');
				const parent = box.closest(".spoller__item");

				if (parent.className === 'spoller__item active') {
					for (let i = 0; i < parents.length; i++) {
						parents[i].classList.remove('active');
					}
				} else {
					for (let i = 0; i < parents.length; i++) {
						parents[i].classList.remove('active');
					}
					parent.classList.add('active');
				}
			});
		}
	};

	const startRatingAnimation = (scoreRes) => { /// eg 90
		console.log('animation started!');
		const arrowEl = document.getElementById("progressbar__arrow");
		let count = -82;
		let scoreNumber = 0;
		let ratingNumber;
		const animateInterval = setInterval(() => {
			arrowEl.style.transform = "rotate(" + count + "deg)";
			ratingNumber = Math.floor((count + 82) / 1.64);
			console.log('animation step started!', ratingNumber);
			setAnimationRatingNum(ratingNumber);
			count++;
			if (count >= scoreRes) {
				clearInterval(animateInterval);
			}
		}, 30);
	};

	const getServeyResult = async () => {
		let videoTime = 1;
		if (window.innerWidth < 576) {
			videoTime = 1;
			setDesktopVideo(false);
		}
		/* document.querySelector('video').play();
		let newServeyObject = {
			...{
				action: 'get_cff_result_by_id'
			}
		}; */
		let form_data = new FormData();
		form_data.append('action', 'get_cff_result_by_id');
		const paramsObj = { action: 'get_cff_result_by_id', proposal_id: id.toString() };
		const params = Serialize(paramsObj);
		axios.get('https://optimai.co.il/wp-admin/admin-ajax.php?' + params).then(function (response) {
			console.log('result ', response.data?.data?.id);
			setCffResult(response.data);
			setServeyCount(parseInt(response.data?.data?.id) + 250000);
			const scoreRes = response.data?.result?.ratings * 1.64 - 82;
			setTimeout(() => {
				setLoadingAnimation(false);
				startRatingAnimation(scoreRes, response.data?.result?.ratings);
				initAccordion();
			}, videoTime);
		});

		/* const result = await Get('https://optimai.co.il/wp-admin/admin-ajax.php', { action: 'calc_mortagage' }); */
	};

	const buttonAnimation = () => {
		let button = document.getElementById('progressbar__button');
		button.classList.add("active");
		
		setTimeout(() => {
			button.classList.remove("active");
		}, 900);
	};

	React.useEffect(() => {
		console.log('our id', id);
		getServeyResult();
		setScore(1.64 * 71 - 82);
		console.log('score', score);
		const buttonInterval = setInterval(buttonAnimation, 2000); 
	}, []);

	/* React.useEffect(() => {
		document.querySelector('video').play();
	}, [desktopVideo]); */

	(function (d) { 
		let s = d.createElement("script"); 
		s.setAttribute("data-account", "es8YPEWAQK"); 
		s.setAttribute("src", "https://cdn.userway.org/widget.js"); 
		(d.body || d.head).appendChild(s); 
	}(document));

	return (
		<>
			<Helmet>
				<title>מחשבון משכנתא חכם - אופטימאי</title>
			</Helmet>

			<noscript>Please ensure Javascript is enabled for purposes of <a href="https://userway.org">website accessibility</a></noscript>
			<script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-9988069504628480" crossorigin="anonymous"></script>
			{loadingAnimation ? (
				<body class="loaded loading-video-body">
					{/* BEGIN main wrapper */}
					<div class="main-wrapper">
						{/* BEGIN MAIN */}
						<main class="main main-video">
							{desktopVideo ? (
								<video src="https://optimai.codersuccess.com/wp-content/uploads/2021/06/desktopcalcanim.m4v" autoplay muted controlslist="nodownload" preload="auto" loop type="video/mp4" controls="hidden" playsinline="playsinline"></video>
							) : (
								<video src="/img/mobile-loading.mp4" autoplay muted controlslist="nodownload" preload="auto" loop type="video/mp4" controls="hidden" playsinline="playsinline"></video>
							)}
						</main>
						{/* MAIN EOF   */}
					</div>
					{/* main wrapper EOF   */}
				</body>
			) : ''}
			<body class="loaded">

				<div id="call-us-popup-modal-container" className={optimaiPopup ? 'optimai' : 'optimai none'}>
					<div id="call-us-modal-wrapper" class="call-us-modal-wrapper">
						<div class="contact-us-popup-wrapper">
							<div class="close-contact-us-popup" onClick={() => setOptimaiPopup(false)}><div class="close-popup-orange-wrapper"><div class="close-popup-white-wrapper"><img src="/img/custom-close-popup-icon.png" alt="" /></div></div></div>
							<div class="contact-us-popup-image-wrapper inline-block">
								<img src="/img/poup-phone-image.png" alt="" />
							</div>
							<div class="contact-us-popup-info-wrapper inline-block">
								<div class="contact-us-popup-ornage-bubble-container">
									<img src="/img/orange-bubble.png" alt="" />
									<div class="contact-us-popup-main-title">אני אופטימאי</div>
									<div class="contact-us-popup-secondary-title">מחשבון משכנתא חכם</div>
								</div>
								<div class="contact-us-popup-text">
									בעוד כמה רגעים אגלה לך<br />כל מה שאתה צריך לדעת<br />על המשכנתא שלך.
								</div>
								<div class="contact-us-popup-blue-button">משהו לא ברור<br />התקשרו <a href="tel:035052400">03-5052400</a></div>
							</div>
							<div class="popup-button" onClick={() => setOptimaiPopup(false)}>הבנתי!</div> 
						</div>
					</div>
				</div>

				{/* BEGIN main wrapper */}
				<div class="main-wrapper">

					{/* BEGIN HEADER */}
					<div class="header-wrapper">
						<header class="header">
							<div class="container header__container">
								<div class="header__logo">
									<a class=" logo"><img src="/img/logo2.png" alt="" onClick={() => setOptimaiPopup(true)} /></a>
									<a href="tel:035052400" class="header-phone">
										03-5052400
										<img src="/img/phone-icon.png" alt="" />
									</a>
								</div>
								<div class="header__icons h-icons">
									<div class="h-icons__item">
										<div class="h-icons__img">
											<img src="/img/homeicon1.png" alt="" />
										</div>
										<div class="h-icons__title">חישוב מספר</div>
										<div class="h-icons__subtitle">{mask(serveyCount)}</div>
									</div>
									<div class="h-icons__item">
										<div class="h-icons__img">
											<img src="/img/homeicon2.png" alt="" />
										</div>
										<div class="h-icons__title">משכנתא בסך</div>
										<div class="h-icons__subtitle">{cffResult ? mask(cffResult?.data?.mortgageAmount) : ''}</div>
									</div>
									<div class="h-icons__item">
										<div class="h-icons__img">
											<img src="/img/homeicon3.png" alt="" />
										</div>
										<div class="h-icons__title">לנכס בשווי</div>
										<div class="h-icons__subtitle">{cffResult ? mask(cffResult?.data?.propertyValue) : ''}</div>
									</div>
								</div>
								<div class="header__edit">
									<a href="/" class="edit-button">
										<img src="/img/edit.png" alt="" />
										<span>ערוך <br />נתונים</span>
									</a>
								</div>
							</div>
						</header>
					</div>

					{/* HEADER EOF   */}

					{/* BEGIN MAIN */}
					<main class="main">
						<section class="rating-section">
							<div class="container">
								<div class="rating-section__head">
									<h2 class="rating-section__title">דירוג <strong>המשכנתא שלך</strong></h2>
									<h3 class="rating-section__subtitle"> מבוסס על נתוני המשכנתא ביחס לנתוניך האישיים.</h3>
								</div>

								<div class="progressbar">
									<div class="progressbar__val">
										{animationRatingNum}
										<div class="progressbar__arrow" id="progressbar__arrow"></div>
									</div>

									<a href={"/lead/" + cffResult?.result?.proposal_id} class="progressbar__button" id="progressbar__button"> המשך לצפייה במשכנתא</a>

									<div class="progressbar__text">פעם ראשונה כאן?</div>
									<div class="progressbar__text2">כדאי לקרוא את ההסבר לפני שממשיכים</div>
								</div> 

								<div class="rating-bg-1">
									<img src="/img/rating-bg-img-1.png" alt="" />
								</div>
								<div class="rating-bg-2">
									<img src="/img/rating-bg-img-2.png" alt="" />
								</div>
								<div class="rating-bg-3">
									<img src="/img/rating-bg-img-3.png" alt="" />
								</div>
							</div>
						</section>
						<section class="question-section">
							<div class="container">
								<div class="spoller spoller-grid">
									<div class="spoller__item">
										<div class="spoller__head ">
											<div class="spoller__title">הסבר על הדירוג</div>
										</div>
										<div class="spoller__body ">
											<p>
												בכל משכנתא (וכל הלוואה) קיים סיכון מסוים למלווה שיאבד את כספי ההלוואה שהעמיד, או את חלקם. רמת הסיכון היא המרכיב המרכזי שמשמש את הבנק לקביעת הריביות על המשכנתא. לכל משכנתא רמת סיכון משלה שמושפעת ממשתנים רבים: מאפיינים פיננסיים של הלווה, ממאפייני המשכנתא וממאפייני הנכס. דירוג המשכנתא שלך הוא ציון בין 1-100 המבטא את רמת הסיכון היחסית של המשכנתא שחישבת בעיני הבנקים.
											</p>
										</div>
									</div>
									<div class="spoller__item">
										<div class="spoller__head">
											<div class="spoller__title">ממה מושפע הדירוג?</div>
										</div>
										<div class="spoller__body">
											<p>דירוג המשכנתא מושפע ממאפיינים פיננסיים של הלווה (כמו היסטורית אשראי והתנהלות פיננסית), מאפייני הנכס ומאפייני המשכנתא. משמעות הדבר היא שללווה מסוים יכולות להיות משכנתאות בדירוגים שונים, ובהתאם לכך, בריביות ובמחירים שונים.</p>
										</div>
									</div>
									<div class="spoller__item">
										<div class="spoller__head">
											<div class="spoller__title">משמעות הדירוג</div>
										</div>
										<div class="spoller__body">
											<p>דירוג גבוה פירושו "משכנתא בטוחה" מבחינת הבנק. מבקשי משכנתאות בדירוג גבוה נמצאים בנקודת פתיחה טובה יותר מול הבנק ובעמדת מיקוח טובה יותר ולכן, נהנים מריביות ותנאים טובים יותר ביחס למבקשי משכנתאות בדירוג נמוך. מבקשי משכנתאות בדירוג נמוך מאוד, עלולים להתקל בסירוב מצד הבנק לבקשת המשכנתא.</p>
										</div>
									</div>
									<div class="spoller__item">
										<div class="spoller__head">
											<div class="spoller__title">שיפור הדירוג</div>
										</div>
										<div class="spoller__body">
											<p>הדירוג הממוצע של המשכנתאות בישראל בשלושת החודשים האחרונים הוא 66.3. חשוב להבין ולזכור שבשונה מדירוג אשראי, הדירוג באופטימאי מתייחס למשכנתא כולה ולא רק ללווה. אמנם מאפייני הלווה משפיעים על הדירוג אבל לא רק הם. שינויים בסכום המשכנתא, בתקופה ובהחזר החודשי, משפיעים גם הם על רמת הסיכון ויכולים לסייע בשיפור הדירוג וקבלת תנאים וריביות טובים יותר.</p>
										</div>
									</div>
								</div>
							</div>
						</section>
					</main>
					{/* MAIN EOF   */}

					{/* BEGIN FOOTER */}
					<footer class="footer">
						<div class="container">
							<p>
								כל הזכויות שמורות לאופטימאי בע"מ | הגלישה באתר מהווה הסכמה   <strong>
									<a href="/regulations" target="_blank">
									  תקנון האתר
									</a>
								  </strong>
								  &nbsp;|&nbsp;
								  <strong>
									<a href="/terms" target="_blank">
									  תנאי ביטול עסקה
									</a>
									|&nbsp;
								  </strong>
								  <strong>
									<a href="/accessibility" target="_blank">
									  הצהרת נגישות
									</a>
								  </strong>
							</p>
						</div>
					</footer>
					{/* FOOTER EOF   */}

				</div>
				{/* main wrapper EOF   */}

			</body>

		</>
	);
};
