import React from 'react';

const host = 'https://tutorialsapi.codersuccess.com';
// const host = 'https://localhost:44341';
export const Post = async (endpoint, payload) => {
    endpoint = endpoint.includes('https://') ? endpoint : host + endpoint;
    console.log('endpoint', endpoint);
    return new Promise((resolve) => {
        let xhr = new XMLHttpRequest();
        xhr.onreadystatechange = function () {
            if (this.readyState === 4 && this.status === 200) {
                const response = JSON.parse(this.response);
                console.log('response group updated', response);
                return resolve(response);
            }
            return resolve(JSON.parse(this.response)); // catch error
        };
        xhr.open('POST', endpoint, true);
        xhr.setRequestHeader('Content-type', 'application/json');
        xhr.send(JSON.stringify(payload));
    });
};

export const HttpLogin = async (endpoint, params) => {
    endpoint = endpoint.includes('https://') ? endpoint : host + endpoint;
    const formData = new FormData();
    formData.append('username', params.username);
    formData.append('password', params.password);
    return new Promise((resolve) => {
        let xhr = new XMLHttpRequest();
        xhr.onreadystatechange = function () {
            if (this.readyState === 4 && this.status === 200) {
                const response = JSON.parse(this.response);
                console.log('project create response', response);
                return resolve(response);
            }
            return false;
        };
        xhr.open('POST', endpoint, true);
        xhr.send(formData);
    });
};

export const PostAuthorized = async (endpoint, payload) => {
    console.log('our token', localStorage.getItem('token'));
    endpoint = endpoint.includes('https://') ? endpoint : host + endpoint;
    return new Promise((resolve) => {
        let xhr = new XMLHttpRequest();
        xhr.onreadystatechange = function () {
            if (this.readyState === 4 && this.status === 200) {
                const response = JSON.parse(this.response);
                console.log('response group updated', response);
                return resolve(response);
            }
            return false;
        };
        xhr.open('POST', endpoint, true);
        xhr.setRequestHeader('Content-type', 'application/json');
        xhr.setRequestHeader('Authorization', 'Bearer ' + localStorage.getItem('token'));
        xhr.send(JSON.stringify(payload));
    });
};

export const Serialize = (obj) => {
    const str = [];
    for (let p in obj) {
        if (obj.hasOwnProperty(p)) {
            str.push(encodeURIComponent(p) + '=' + encodeURIComponent(obj[p]));
        }
    }
    return str.join('&');
};

const createFormData = (obj) => {
    const formData = new FormData();
    for (let p in obj) {
        if (obj.hasOwnProperty(p)) {
            formData.append(p, obj[p]);
        }
    }
    console.log('our form data', formData);
    return formData;
};

export const PostFormDataAuthorized = async (endpoint, payload, formParams) => {
    endpoint = endpoint.includes('https://') ? endpoint : host + endpoint;
    const formData = createFormData(formParams);
    let queryString = '';
    if (payload !== '') {
        queryString = '?' + Serialize(payload);
    }
    return new Promise((resolve) => {
        let xhr = new XMLHttpRequest();
        xhr.onreadystatechange = function () {
            if (this.readyState === 4 && this.status === 200) {
                const response = JSON.parse(this.response);
                console.log('post form response', response);
                return resolve(response);
            }
            return false;
        };
        xhr.open('POST', endpoint + queryString, true);
        xhr.setRequestHeader('Authorization', 'Bearer ' + localStorage.getItem('token'));
        xhr.send(formData);
    });
};

export const GetAthourized = async (endpoint, payload) => {
    try {
        endpoint = endpoint.includes('https://') ? endpoint : host + endpoint;
        let queryString = '';
        if (payload !== '') {
            queryString = '?' + Serialize(payload);
        }

        let response = await fetch(endpoint + queryString, {
            method: 'get',
            headers: new Headers({
                Authorization: 'Bearer ' + localStorage.getItem('token'),
                'Content-Type': 'application/json'
            })
        });

        const json = await response.json();
        console.log('get json result', json);
        return json;
    } catch (error) {
        console.log('get json result error', error);
        return false;
    }
};

export const DeleteAthourized = async (endpoint, payload) => {
    endpoint = endpoint.includes('https://') ? endpoint : host + endpoint;
    let queryString = '';
    if (payload !== '') {
        queryString = '?' + Serialize(payload);
    }

    let response = await fetch(endpoint + queryString, {
        method: 'DELETE',
        headers: new Headers({
            Authorization: 'Bearer ' + localStorage.getItem('token'),
            'Content-Type': 'application/json'
        })
    });
    const json = await response.json();
    console.log('get json result', json);
    return json;
};

export const Get = async (endpoint, payload) => {
    endpoint = endpoint.includes('https://') ? endpoint : host + endpoint;
    let queryString = '';
    if (payload !== '') {
        queryString = '?' + Serialize(payload);
    }

    let response = await fetch(endpoint + queryString, {
        method: 'get'
    });
    const json = await response;
    console.log('get json result', json);
    return json;
};

export default Get;
