import { isEmptyArray } from 'formik';
import * as React from 'react';
import { useState } from 'react';
import './active-number-input.css';

export const ActiveSelectInput = ({ setValue, value, selectOptions, id, placeHolder, disabled, selectId, EmptyMassage, prevSelectId }) => {
    const [open, setOpen] = useState(false);
    const warningLifeSecs = 5000;

    const IsEmpty = (val) => {
        const m = val === 0 || val === '' || val === null || val === undefined;
        return m;
    };

    const setNewValue = (newValue) => {
        const el = document.getElementById(selectId);
        el.classList.remove("error");
        
        console.log('EmptyMassage', EmptyMassage);

        if (IsEmpty(EmptyMassage)) {
            const pr = document.getElementById('question-two-prev-empty');
            pr.classList.add("none");
        } else if (EmptyMassage === 'question-two-prev-empty') {
            const pr = document.getElementById('question-three-prev-empty');
            pr.classList.add("none");
        } 
        setValue(newValue);
        setOpen(false);
    };

    const toggleOptions = () => {
        if (open) {
            setOpen(false);
        } else {
            setOpen(true);
        }
    };

    const showFieldWarning = (currInputMassageId, prevInputId) => {
        console.log('showFieldWarning active');
        const me = document.getElementById(currInputMassageId);
        if (me !== null) {
            me.classList.remove("none");
            setTimeout(() => {
                me.classList.add("none");
            }, warningLifeSecs);
        }

        const el = document.getElementById(prevInputId);
        if (el !== null) {
            el.classList.add("error");
        }
    };

    return (
        <div className="selectbox selectbox--style2">
            <div className={disabled ? 'selectbox__head active' : 'selectbox__head'} id={selectId} onClick={disabled ? '' : toggleOptions}>
                <div class="disable" onClick={() => showFieldWarning(EmptyMassage, prevSelectId)}></div>
                { IsEmpty(value) ? (
                <div class="selectbox__text">
                    בחר
                </div> 
                ) : (
                <div class="selectbox__text">
                    {value.name}
                </div> 
                )} 
                <div class="selectbox__caret"></div>
            </div>            
            {open ? (
                <div className={disabled ? 'selectbox__drop none' : 'selectbox__drop'}>
                    <ul>
                    {selectOptions.map((option) => (
                        <li onClick={() => setNewValue(option)}>
                            {option.name}
                        </li>
                    ))}
                    </ul>
                </div>
            ) : ''}
        </div>
    );
};
