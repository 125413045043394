import 'simplebar/dist/simplebar.min.css';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import {
  action,
  createStore,
  StoreProvider
} from 'easy-peasy';
import App from './app';
import { ProgressSpinner } from './components/progress';

const store = createStore({
  isProgress: false,
  isOpen: false,
  notice: '',
  btnName: 'CLOSE', 
  callback: null,
  reload: false,
  isCallBack: false,
  isLogOutBtn: false,
  config: {
    
  },
  articlePopup: {
    isOpen: false,
    articleName: null
  },
  linkButton: {
    isLinkButton: false,
    linkButtonLink: '',
    linkButtonText: '',
  },
  openArticle: action((state, payload) => {
    state.articlePopup.isOpen = true;
    state.articlePopup.articleName = payload.articleName;
  }),
  closeArticle: action((state, payload) => {
    state.articlePopup.isOpen = false;
    state.articlePopup.articleName = '';
  }),
  open: action((state, payload) => {
    console.log('payload', payload);
    state.isOpen = true;
    state.notice = payload.notice;
    state.callback = payload.callback;
    state.btnName = payload.btnName !== undefined ? payload.btnName : 'סגור';
    state.isCallBack = payload.isCallBack !== undefined ? payload.isCallBack : false;
    state.isLogOutBtn = payload.isLogOutBtn !== undefined ? payload.isLogOutBtn : false;
    state.reload = payload.reload !== undefined ? payload.reload : false;
    if (payload.linkButton !== undefined) {
      state.linkButton.isLinkButton = payload.isLinkButton;
      state.linkButton.linkButtonLink = payload.linkButtonLink;
      state.linkButton.isLinkButton = payload.isLinkButton;
    }
  }),
  close: action((state, payload) => {
    state.isOpen = false;
    state.notice = '';
    if (state.reload === true) window.location.reload();
  }),
  showSpinner: action((state, payload) => {
    state.isProgress = true;
  }),
  hideSpinner: action((state, payload) => {
    state.isProgress = false;
  })
});

ReactDOM.render((
  <StoreProvider store={store}>
    <BrowserRouter>
      <ProgressSpinner />
      <App />
    </BrowserRouter>
  </StoreProvider>
), document.getElementById('root'));
