import * as React from 'react';
import GooglePlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-google-places-autocomplete';
/* import { geocodeByAddress, getLatLng } from 'react-google-places-autocomplete'; */
import { ActiveSelectInput } from '../../components/optimaiComponents/active-select-input';
// import '../css/style.css';

export const ServeyStepThree = ({ serveyObject, setServeyObject, setCurrentStep, currentStep }) => {
  const [questionTwo, setQuestionTwo] = React.useState(false);
  const [questionThree, setQuestionThree] = React.useState(false);
  const [nextButton, setNextButton] = React.useState(false);
  const [answeredQuestions, setAnsweredQuestions] = React.useState(0);
  const [localServeyObject, setLocalServeyObject] = React.useState(0);
  const [localDob, setLocalDob] = React.useState(0);
  const [dobList, setDobList] = React.useState(0);
  const [city, setCity] = React.useState(null);
  const [gpsX, setGpsX] = React.useState(0);
  const [gpsY, setGpsY] = React.useState(0);
  const newDobList = [];
  const [localKids, setLocalKids] = React.useState(0);
  const kidsList = [
    { name: 'אין', value: '0' },
    { name: '1', value: '1' },
    { name: '2', value: '2' },
    { name: '3', value: '3' },
    { name: '4+', value: '4' }
  ];

  const saveServeyResult = () => {
    let newServeyObject = {
      ...serveyObject,
      ...{
        city: city,
        dob: localDob,
        kids: localKids
      }
    };
    setServeyObject(newServeyObject);
    localStorage.setItem('serveyObject', JSON.stringify(newServeyObject));
    setCurrentStep(currentStep + 1);
  };

  const prevStep = () => {
    let newServeyObject = {
      ...serveyObject,
      ...{
        city: city,
        dob: localDob,
        kids: localKids
      }
    };
    setServeyObject(newServeyObject);
    localStorage.setItem('serveyObject', JSON.stringify(newServeyObject));
    setCurrentStep(currentStep - 1);
  };

  const IsEmpty = (value) => {
    const m = value === 0 || value === '' || value === null || value === undefined;
    return m;
  };

  const NotEmpty = (value) => {
    const m = value !== 0 && value !== '' && value !== null && value !== undefined;
    return m;
  };

  React.useEffect(() => {
    window.scrollTo({ top: 0 });

    let newServeyObject = {
      ...localServeyObject,
      ...{
        city: serveyObject['city']
      }
    };
    setLocalServeyObject(newServeyObject);
    setLocalDob(serveyObject['dob']);
    setLocalKids(serveyObject['kids']);

    if (NotEmpty(serveyObject['city'])) {
      setCity(serveyObject['city']);
      const cityName = document.querySelector(".css-1wa3eu0-placeholder");
      cityName.innerHTML = serveyObject['city'];
    }

    if (NotEmpty(serveyObject['dob'])) {
      const dob = document.querySelector("#dob .selectbox__text");
      dob.innerHTML = serveyObject['dob'].name;
    }

    if (NotEmpty(serveyObject['kids'])) {
      const kids = document.querySelector("#kids .selectbox__text");
      kids.innerHTML = serveyObject['kids'].name;
    }

    for (let i = 0; i <= 56; i++) {
      newDobList[i] = {};
      newDobList[i].name = 2002 - i;
      newDobList[i].value = 2002 - i;
    }

    setDobList(newDobList);
  }, []);

  const readInputValue = (identificator, prevEmptyMassage) => {
    const el = document.getElementById(identificator);
    el.classList.remove("error");
    const pr = document.getElementById(prevEmptyMassage);
    if (pr !== null) {
      pr.classList.add("none");
    }
    const localServeyObjectSave = {};
    const inputValue = document.getElementById(identificator).value;
    localServeyObjectSave[identificator] = inputValue;
    let newLocalServeyObject = {
      ...localServeyObject,
      ...localServeyObjectSave
    };
    setLocalServeyObject(newLocalServeyObject);
  };

  /*  function initAutocomplete() {
     console.log('try to autocomplete ini it');
     // Create the autocomplete object, restricting the search to geographical
     // location types.    
     autocomplete = new google.maps.places.Autocomplete((document.getElementById('autocomplete')), { types: ['geocode'] });
     autocomplete.setComponentRestrictions({
       country: ['il'],
     });
   } */

  React.useEffect(() => {
    if (answeredQuestions === 0) {
      setQuestionTwo(false);
      setQuestionThree(false);
      setNextButton(false);
    } else if (answeredQuestions === 'openQuestionTwo') {
      setQuestionTwo(true);
      setQuestionThree(false);
      setNextButton(false);
    } else if (answeredQuestions === 'openQuestionThree') {
      setQuestionTwo(true);
      setQuestionThree(true);
      setNextButton(false);
    } else if (answeredQuestions === 'openNextButton') {
      setQuestionTwo(true);
      setQuestionThree(true);
      setNextButton(true);
    }
  }, [answeredQuestions]);

  React.useEffect(() => {
    if (NotEmpty(city) && answeredQuestions === 0) {
      setAnsweredQuestions('openQuestionTwo');
    } else if (IsEmpty(city) && questionTwo) {
      setAnsweredQuestions(0);
    }
  }, [city]);

  React.useEffect(() => {
    if (NotEmpty(localDob) && NotEmpty(city)) {
      setAnsweredQuestions('openQuestionThree');
    } else if (IsEmpty(localDob) && questionThree) {
      setAnsweredQuestions('openQuestionTwo');
    }
  }, [localDob]);

  React.useEffect(() => {
    if (IsEmpty(city)) {
      setAnsweredQuestions(0);
    } else if (NotEmpty(city) && NotEmpty(localKids) && NotEmpty(localDob)) {
      setAnsweredQuestions('openNextButton');
    }
  }, [localServeyObject, localKids, localDob]);

  React.useEffect(() => {
    if (city !== null) {
      if (city.label !== undefined) {
        setCity(city.label);
      } 
    }
  }, [city]);

  return (
    <>
      <div class="home-page__bg-1">
        <img src="img/home-page-bg-1.png" alt="" />
      </div>
      <div class="container">
        <div class="step">
          <div class="step__item">
            <div class="step__inner">
              4
            </div>
          </div>
          <div class="step__item">
            <div class="step__inner">
              3
            </div>
          </div>
          <div class="step__item ">
            <div class="step__inner step__inner--active">
              2
            </div>
          </div>
          <div class="step__item">
            <div class="step__inner step__inner--active">
              1
            </div>
          </div>
        </div>
        <div class="home-page__wrapper">
          <div class="home-page__form hp-form">
            <div class="hp-form__row">
              <div class="hp-form__item">
                <div class="hp-form__title">
                  <span>ילדים בחזקתך</span>
                </div>
                <div class="hp-form__field">
                  <ActiveSelectInput selectOptions={kidsList} value={localKids} setValue={setLocalKids} disabled={!questionThree} EmptyMassage="question-three-prev-empty" selectId="kids" prevSelectId="dob" />
                  <div class="form-message none" Id="question-three-prev-empty">
                    <div class="form-message__text">חסר נתון קודם</div>
                    <div class="form-message__icon"><img src="img/STOP-ICON.png" alt="" /></div>
                  </div>
                  {IsEmpty(localKids) && questionThree ? (
                    <div class="form-message">
                      <div class="form-message__text">
                        רק ילדים מתחת לגיל 18.
                      </div>
                      <div class="form-message__icon"><img src="img/QUES-ICON.png" alt="" /></div>
                    </div>
                  ) : ''}
                  {NotEmpty(localKids) && questionThree ? (
                    <div class="form-message">
                      <div class="form-message__text">
                        אתה כבר יודע... לחץ על הבא.
                      </div>
                      <div class="form-message__icon"><img src="img/OK-ICON.png" alt="" /></div>
                    </div>
                  ) : ''}
                </div>
              </div>
              <div class="hp-form__item">
                <div class="hp-form__title">
                  <span>שנת לידה</span>
                </div>
                <div class="hp-form__field">
                  <ActiveSelectInput selectOptions={dobList} value={localDob} setValue={setLocalDob} disabled={!questionTwo} EmptyMassage="question-two-prev-empty" selectId="dob" prevSelectId="city" />
                  <div class="form-message none" Id="question-two-prev-empty">
                    <div class="form-message__text">חסר נתון קודם</div>
                    <div class="form-message__icon"><img src="img/STOP-ICON.png" alt="" /></div>
                  </div>
                  {IsEmpty(localDob) && questionTwo ? (
                    <div class="form-message">
                      <div class="form-message__text">
                        באיזו שנה נולדת?
                      </div>
                      <div class="form-message__icon"><img src="img/QUES-ICON.png" alt="" /></div>
                    </div>
                  ) : ''}
                  {NotEmpty(localDob) && questionTwo ? (
                    <div class="form-message">
                      <div class="form-message__text">
                        אני נולדתי ב 2019.
                      </div>
                      <div class="form-message__icon"><img src="img/OK-ICON.png" alt="" /></div>
                    </div>
                  ) : ''}
                </div>
              </div>
              <div class="hp-form__item">
                <div class="hp-form__title">
                  <span>עיר מגורים</span>
                </div>
                <div class="hp-form__field">
                  {/* <input type="text" class="form-input-style2" onChange={() => readInputValue('city', 'question-two-prev-empty')} id="city" placeholder="הקלד" /> */}
                  <GooglePlacesAutocomplete
                    apiKey="AIzaSyAUZOM-LeHgxIrV-Oj5i0Ic1R6fRktNQv0"
                    selectProps={{
                      city,
                      onChange: setCity,
                      placeholder: 'עיר מגורים',
                    }}
                    autocompletionRequest={{
                      componentRestrictions: {
                        country: ['il'],
                      }
                    }}
                  />
                  {IsEmpty(city) ? (
                    <div class="form-message">
                      <div class="form-message__text">
                        הזן 2 אותיות ובחר מהרשימה
                      </div>
                      <div class="form-message__icon"><img src="img/QUES-ICON.png" alt="" /></div>
                    </div>
                  ) : (
                    <div class="form-message">
                      <div class="form-message__text">
                        אוקיי, אפשר להמשיך.
                      </div>
                      <div class="form-message__icon"><img src="img/OK-ICON.png" alt="" /></div>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div class="hp-form__buttons">
              <button type="button" class="button active" onClick={prevStep}>הקודם</button>
              <button type="button" class="button next-button" onClick={() => saveServeyResult()} disabled={!nextButton}>הבא</button>
            </div>
          </div>
        </div>
      </div>
      <div class="home-page__bg-2">
        <img src="img/home-page-bg-2.2.png" alt="" class="hidden-iphone" />
        <img src="img/home-page-bg-2.2-mobile.png" alt="" class="visible-iphone" />
      </div>
    </>
  );
};
