export const QueryStringToJSON = () => {
  const qs = window.location.search.slice(1);
  if (qs === '') {
    return false;
  }
  console.log('our query string', qs);
  const pairs = qs.split('&');
  let result = {};
  pairs.forEach(function (p) {
    const pair = p.split('=');
    const key = pair[0];
    const value = decodeURIComponent(pair[1] || '');
    if (result[key]) {
      if (Object.prototype.toString.call(result[key]) === '[object Array]') {
        result[key].push(value);
      } else {
        result[key] = [result[key], value];
      }
    } else {
      result[key] = value;
    }
  });
  return JSON.parse(JSON.stringify(result));
};

export const ShowDateRange = (showDateRangeObj) => {
  document.getElementById('from').value = showDateRangeObj.from;
  document.getElementById('to').value = showDateRangeObj.to;
};

export const GetServeyServerObjectReady = (serveyObject) => {
  const objectReady = {};
  objectReady.checks = serveyObject.checks.value;
  objectReady.city = serveyObject.city;
  objectReady.dob = serveyObject.dob.value;
  objectReady.kids = serveyObject.kids.value;
  objectReady.lates = serveyObject.lates.value;
  objectReady.loanRepayments = serveyObject.loanRepayments;
  objectReady.loanUrgency = serveyObject.loanUrgency.value;
  objectReady.monthlyIncome = serveyObject.monthlyIncome;
  objectReady.mortgageAmount = serveyObject.mortgageAmount;
  objectReady.mortgageGoal = serveyObject.mortgageGoal.value;
  objectReady.osh = serveyObject.osh.value;
  objectReady.propertyAddress = serveyObject.propertyAddress.value;
  objectReady.propertyType = serveyObject.propertyType.value;
  objectReady.propertyValue = serveyObject.propertyValue;
  objectReady.repayments = serveyObject.repayments;
  objectReady.seniority = serveyObject.seniority.value;
  return objectReady;
};

export const GetDateRange = () => {
  const queryObj = QueryStringToJSON();
  let isToday = false;
  if (!queryObj) {
    isToday = true;
  }
  const yourDate = new Date();
  let from = yourDate.toISOString().split('T')[0];
  let to = yourDate.toISOString().split('T')[0];
  console.log('from ', from);
  if (!isToday) {
    from = queryObj.from;
    to = queryObj.to;
  } else {
    ShowDateRange({ from, to });
  }
  return { from, to };
};

export const SetUrl = (newUrl) => {
  window.history.pushState({ html: '', pageTitle: '' }, '', '/');
  window.history.pushState({ html: '', pageTitle: '' }, '', newUrl);
};
