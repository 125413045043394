import { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useStoreActions } from 'easy-peasy';
import { Box, Button, Card, Container, Divider, Grid, TablePagination, Typography } from '@material-ui/core';
import { SummaryItem } from '../components/reports/summary-item';
import { OrdersFilter } from '../components/orders/orders-filter';
import { CampaignsTable } from '../components/tables/campaigns-table';
import { Get, GetAthourized } from "../services/http";
import { Cash as CashIcon } from '../icons/cash';
import { SetUrl, ShowDateRange, GetDateRange, QueryStringToJSON } from '../services/dateRangeService';
import LoginDialog from '../components/login';
import AddTask from '../components/add-task';

export const Campaigns = () => {
  const [mode, setMode] = useState('table');
  const [query, setQuery] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [page, setPage] = useState(0);
  const [totalHours, settotalHours] = useState(0);
  const [totalHoursPaid, settotalHoursPaid] = useState(0);
  const [totalHoursApproved, settotalHoursApproved] = useState(0);
  const [clicksTotal, setClicksTotal] = useState(0);
  const [impressionsTotal, setImpressionsTotal] = useState(0);
  const [costTotal, setCostTotal] = useState(0);
  const [tsConversions, setTsConversions] = useState(0);
  const [publisherRevenueTotal, setPublisherRevenueTotal] = useState(0);
  const [campaigns, setCampaigns] = useState(null);
  const [originCampaigns, setOriginCampaigns] = useState(null);
  const [originCampaignsFilter, setOriginCampaignsFilter] = useState(null);
  const showSpinner = useStoreActions((action) => action.showSpinner);
  const hideSpinner = useStoreActions((action) => action.hideSpinner);
  const [userInfo, setUserInfo] = useState(null);
  const [allUsers, setAllUsers] = useState(null);

  const numberWithCommas = (number) => {
    const intValue = parseInt(number);
    return intValue?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
  };

  const stats = [
    {
      content: numberWithCommas(totalHours),
      icon: CashIcon,
      label: 'Total Hours'
    },
    {
      content: numberWithCommas(totalHoursApproved),
      icon: CashIcon,
      label: 'Total Hours Approved'
    },
    {
      content: numberWithCommas(totalHoursPaid),
      icon: CashIcon,
      label: 'Total Hours Paid'
    }
  ];

  const calculationTotalHours = (tasks) => {
    let totalhours = 0;
    let totalhoursPaid = 0;
    let totalhoursApproved = 0;
    tasks?.forEach((item) => {
      totalhours += item.hours;
      if (item.isPaid === true) {
        totalhoursPaid += item.hours;
      }
      if (item.isApprovedByClient === true) {
        totalhoursApproved += item.hours;
      }
    });
    settotalHours(totalhours);
    settotalHoursPaid(totalhoursPaid);
    settotalHoursApproved(totalhoursApproved);
  };

  const handleModeChange = (event, newMode) => {
    if (newMode) {
      setMode(newMode);
    }
  };

  const handleQueryChange = (newQuery) => {
    setQuery(newQuery);
    console.log('newQuery', newQuery);
    const newSearch = originCampaigns.filter((item) => {
      if (item.clientName !== null && item.clientName !== undefined) {
        return item.clientName.includes(newQuery);
      } else {
        return false;
      }
    });
    setCampaigns(newSearch);
    setOriginCampaignsFilter(newSearch);
    calculationTotalHours(newSearch);
  };

  const handleQueryChangeTask = () => {
    const taskUserName = document.getElementById('taskUserName').value;
    if (taskUserName !== "") {
      const newSearch = originCampaignsFilter.filter((item) => {
        if (item.taskUserIdName !== null && item.taskUserIdName !== undefined) {
          return item.taskUserIdName === taskUserName;
        } else {
          return false;
        }
      });
      setCampaigns(newSearch);
      calculationTotalHours(newSearch);
  } else {
      setCampaigns(originCampaignsFilter);
  }
  };

  function onlyUniqueCampId(value, index, self) {
    const result = self.findIndex((x) => x.campaignID === value.campaignID);
    return result === index;
  }
  const getCampaignsIdsList = (list) => {
    return list.filter(onlyUniqueCampId);
  };

  // const totalCpc = tncCampaignList.map((item) => item.cpc).reduce((total, value) => total + value);
// tncCampaignList.filter((item) => item.cpc == "").reduce((total, value) => total + value);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const getTasks = async () => {
    showSpinner();
    const dateRange = GetDateRange();
    const serverCampaigns = await GetAthourized('/api/client/GetTasks', { from: dateRange.from, to: dateRange.to, type: 'created' });// { from: dateRange.from, to: dateRange.to }); { from: '2022-05-28', to: '2022-05-28' });    
    hideSpinner();
    console.log('serverCampaigns', serverCampaigns);
    console.log('userInfo', userInfo);
    if (userInfo?.phoneNumber === "developer") {
      const newSearch = serverCampaigns.tasks.filter((item) => {
        if (item.taskUserIdName !== null && item.taskUserIdName !== undefined) {
          return item.taskUserIdName === userInfo?.userName;
        } else {
          return false;
        }
      });
      setCampaigns(newSearch);
      setOriginCampaigns(newSearch);
      calculationTotalHours(newSearch);
    } else {
      setCampaigns(serverCampaigns.tasks); // new array
      calculationTotalHours(serverCampaigns.tasks);
      setOriginCampaigns(serverCampaigns.tasks);
      setOriginCampaignsFilter(serverCampaigns.tasks);
    } 
  };

  const initSearch = () => {
    const from = document.getElementById('from').value;
    const to = document.getElementById('to').value;
    const newUrl = 'dashboard/campaigns/?from=' + from + '&to=' + to;
    SetUrl(newUrl);
    getTasks();
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const getUser = async () => {
    const serverUser = await GetAthourized('/api/client/GetUser');
    if (serverUser.result === true) {
      setUserInfo(serverUser.user);
      localStorage.setItem('userrole', serverUser.user.phoneNumber);
      console.log('serverUser.user', serverUser.user);
    }
    getTasks();
  };

  const getAllUsers = async () => {
		const serverCampaigns = await GetAthourized('/api/client/GetAllUsers');
		setAllUsers(serverCampaigns.users);
	};

  useEffect(() => {
    getUser();
    getAllUsers();
    if (QueryStringToJSON) {
      const dateRangeObj = GetDateRange();
      ShowDateRange(dateRangeObj);
    }
  }, []);
  return (
    <>
      <Helmet>
        <title>Campaigns | TelavivPituah Dashboard</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          pb: 3,
          pt: 8
        }}
      >
        <Container maxWidth="xxl">
          <Box
            sx={{
              alignItems: 'center',
              display: 'flex',
              mb: 3
            }}
          >
            <Typography
              color="textPrimary"
              variant="h4"
            >
              My Tasks
            </Typography>
            <Box sx={{ flexGrow: 1 }} />
            {userInfo?.phoneNumber === 'admin' || userInfo?.phoneNumber === 'manager' ? (<AddTask />) : ''}
          </Box>
          <Grid
            container
            spacing={3}
          >
            {stats.map((item) => (
              <Grid
                item
                key={item.label}
                md={4}
                xs={12}
              >
                <SummaryItem
                  content={item.content}
                  label={item.label}
                />
              </Grid>
            ))}
          </Grid>
          <Card variant="outlined" style={{ marginBottom: '20px' }}>
            <OrdersFilter
              mode={mode}
              onModeChange={handleModeChange}
              onQueryChange={handleQueryChange}
              query={query}
            />
          </Card>
          <span>From</span>
          <input type="date" id="from" />
          <span>To</span>
          <input type="date" id="to" />
          <span type="submit" onClick={() => initSearch()} style={{ cursor: 'pointer' }}>Search</span>
          <span style={{ margin: '10px' }}>Filter</span>
					{userInfo?.phoneNumber === 'admin' || userInfo?.phoneNumber === 'manager' ? (
          <select class="form-select top_lang" id="taskUserName" onChange={handleQueryChangeTask}>
						<option checked value="">User Name</option>
						{allUsers?.map((item, index) => (
							<option value={item.userName}>
								{item.userName}
							</option>
						))}
					</select>
          ) : ''}
          <CampaignsTable campaigns={campaigns} />
        </Container>
      </Box>
    </>
  );
};
