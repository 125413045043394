import * as React from 'react';
import Lottie from 'react-lottie';
import axios from 'axios';
import { Helmet } from 'react-helmet';
import { useParams } from 'react-router-dom';
import { Get, Post, Serialize } from '../services/http';
import bannerData from '../animations/source/banner.json';
import { LinkCss } from '../services/cssService';
import { Header } from './header';
import { Footer } from './footer';

let interval;

export const Homepage = () => {
	const [openVideoPopup, setOpenVideoPopup] = React.useState(false);
	const [videoLink, setVideoLink] = React.useState();
	const [currSlide, setCurrSlide] = React.useState(3);
	const [slideSide, setSlideSide] = React.useState('');
	let intervals = [];
	let currSLideVar = 3;
	let url = window.location.origin;
	const sliderItems = [
		{ link: "Rn9qNfdbilk", img: "computer-alon.png" },
		{ link: "9z-eJW_hLN0", img: "computer-ben.jpg" },
		{ link: "U7pTkmUpbkU", img: "computer-dv.jpg" },
		{ link: "HX_p5eoEyp0", img: "computer-gadi.png" },
		{ link: "2yLC2XKYu1M", img: "computer-moshe.jpg" }
	];
	const sliderPoints = [
		{ index: 1 },
		{ index: 2 },
		{ index: 3 },
		{ index: 4 },
		{ index: 5 }
	];

	const restoreServeyObject = () => {
		const restoredServeyObjectString = localStorage.getItem('serveyObject');
		const serveyObjectReady = JSON.parse(restoredServeyObjectString);

		console.log("serveyObjectReady", serveyObjectReady);

		let newServeyObject = {
			...serveyObjectReady,
			...{
				url: url
			}
		};

		console.log("newServeyObject", newServeyObject);

		localStorage.setItem('serveyObject', JSON.stringify(newServeyObject));
	}; 

	React.useEffect(() => {
		LinkCss("/css/homepage-style.css");

		if (window.innerWidth < 1200) {
			let nav = document.getElementsByClassName("how-it-works-home-tabs__navigation")[0];

			nav.innerHTML = "";
		}
		/* 	axios.get('http://localhost:3500/img/optimai-video.mp4').then(function (response) {
				const videoBlob = response;
				console.log('our video file here', videoBlob);
				const vid = URL.createObjectURL(videoBlob.data);
				document.getElementById("optimai-video").src = vid;
			}); */
		/* const req = new XMLHttpRequest();
		req.open('GET', '/img/optimai-video.mp4', true);
		req.responseType = 'blob';

		req.onload = function () {
			// Onload is triggered even on 404
			// so we need to check the status code
			if (this.status === 200) {
				const videoBlob = this.response;
				const vid = URL.createObjectURL(videoBlob); // IE10+
				console.log('uploaded!');
				alert('uploaded');
				// Video is now downloaded
				// and we can set it as source on the video element
				document.getElementById("optimai-video").src = vid;
			}
		};
		req.onerror = function (error) {
			alert('error');
			alert(error);
		};

		req.send(); */

		restoreServeyObject();
	}, []);

	const openHowVideo = () => {
		let popup = document.getElementById("video-popup");
		let playButton = document.querySelector("#video-popup .play-button");

		playButton.style.display = "flex";
		popup.classList.add("active");
	};

	const closeVideoPopup = () => {
		let popup = document.getElementById("video-popup");
		let video = document.getElementById("optimai-video");

		video.pause();
		video.currentTime = 0;
		popup.classList.remove("active");
	};

	const playHowVideo = () => {
		let video = document.getElementById("optimai-video");
		let playButton = document.querySelector("#video-popup .play-button");

		video.play();
		playButton.style.display = "none";
	};

	const openTab = (tabId) => {
		let clickedTab = document.getElementById(tabId);
		let tabs = document.querySelectorAll(".how-it-works-home-tabs__body.swiper");
		let tabstitles = document.querySelectorAll(".how-it-works-home-tabs__title");
		let tabTitle = document.getElementById(tabId + "-title");

		for (const tab of tabs) {
			tab.classList.remove("active");
		}

		clickedTab.classList.add("active");

		for (const tabstitle of tabstitles) {
			tabstitle.classList.remove("_tab-active");
		}

		tabTitle.classList.add("_tab-active");
	};

	const openVideo = (link) => {
		console.log("work", openVideoPopup);

		setVideoLink(link);
		setOpenVideoPopup(true);

		console.log("work", openVideoPopup);
	};

	const closeVideo = () => {
		setOpenVideoPopup(false);
	};

	const initAccordion = () => {
		const boxes = document.querySelectorAll('.spoller__head');

		for (const box of boxes) {
			box.addEventListener('click', function handleClick() {
				const parents = document.querySelectorAll('.spoller__item');
				const parent = box.closest(".spoller__item");

				if (parent.className === 'spoller__item active') {
					for (let i = 0; i < parents.length; i++) {
						parents[i].classList.remove('active');
					}
				} else {
					for (let i = 0; i < parents.length; i++) {
						parents[i].classList.remove('active');
					}
					parent.classList.add('active');
				}
			});
		}
	};

	React.useEffect(() => {
		initAccordion();
	}, []);

	const SliderNum = (num) => {
		if (num === (sliderItems.length + 2)) {
			num = 2;
		}

		if (num === (sliderItems.length + 1)) {
			num = 1;
		}

		if (num === 0) {
			num = sliderItems.length;
		}

		if (num === -1) {
			num = sliderItems.length - 1;
		}

		return num;
	};

	const SliderNextAuto = () => {
		let itemNum = currSLideVar;

		itemNum--;

		setSlideSide('left');
		setCurrSlide(SliderNum(itemNum));
		currSLideVar = SliderNum(itemNum);
	};

	const startAnimation = () => {
		clearInterval(interval);
		interval = setInterval(SliderNextAuto, 3000);
	};

	const SliderNext = () => {
		clearInterval(interval);
		let itemNum = currSlide;

		itemNum--;

		setSlideSide('left');
		setCurrSlide(SliderNum(itemNum));
		currSLideVar = SliderNum(itemNum);
		interval = setTimeout(startAnimation, 10000);
	};

	const SliderPrev = () => {
		clearInterval(interval);
		let itemNum = currSlide;

		itemNum++;

		setSlideSide('right');
		setCurrSlide(SliderNum(itemNum));
		currSLideVar = SliderNum(itemNum);
		interval = setTimeout(startAnimation, 10000);
	};

	const SetItemNum = (num) => {
		if (num > currSlide) {
			setSlideSide('right');
		} else if (num < currSlide) {
			setSlideSide('left');
		}

		setCurrSlide(num);
	};

	React.useEffect(() => {
		let slideItems = document.querySelectorAll('.gallery-slider__item');

		for (let i = 0; i < slideItems.length; i++) {
			slideItems[i].classList.remove('active');
			slideItems[i].classList.remove('left');
			slideItems[i].classList.remove('right');
			slideItems[i].classList.remove('nowSlide');
			slideItems[i].classList.remove('prevSlide');
			slideItems[i].classList.remove('nextSlide');
			slideItems[i].classList.remove('twoPrevSlide');
			slideItems[i].classList.remove('twoNextSlide');
		}

		let twoNextSlide = document.getElementById('gallery-slider__item-' + SliderNum(currSlide + 2));
		let nextSlide = document.getElementById('gallery-slider__item-' + SliderNum(currSlide + 1));
		let nowSlide = document.getElementById('gallery-slider__item-' + currSlide);
		let prevSlide = document.getElementById('gallery-slider__item-' + SliderNum(currSlide - 1));
		let twoPrevSlide = document.getElementById('gallery-slider__item-' + SliderNum(currSlide - 2));

		nowSlide.classList.add('nowSlide');
		prevSlide.classList.add('prevSlide');
		nextSlide.classList.add('nextSlide');
		twoPrevSlide.classList.add('twoPrevSlide');
		twoNextSlide.classList.add('twoNextSlide');

		if (slideSide === 'left') {
			nowSlide.classList.add('left');
			prevSlide.classList.add('left');
			nextSlide.classList.add('left');
			twoPrevSlide.classList.add('left');
			twoNextSlide.classList.add('left');
		} else {
			nowSlide.classList.add('right');
			prevSlide.classList.add('right');
			nextSlide.classList.add('right');
			twoPrevSlide.classList.add('right');
			twoNextSlide.classList.add('right');
		}

		setTimeout(() => {
			nowSlide.classList.add('active');
			prevSlide.classList.add('active');
			nextSlide.classList.add('active');
			twoPrevSlide.classList.add('active');
			twoNextSlide.classList.add('active');
		}, 100);
	}, [currSlide]);

	React.useEffect(() => {
		clearInterval(interval);
	}, []);

	const sliderSlide = (event, slideNum) => {
		let element = event.target;
		let sliderWrap = element.parentElement.parentElement;
		let itemsWrap = sliderWrap.getElementsByClassName("how-it-works-home-tabs__item")[0];

		sliderWrap.dataset.currSlideNum = slideNum;

		itemsWrap.style.cssText += "transform: translateX(" + (sliderWrap.clientWidth * slideNum) + "px);";

		let navs = sliderWrap.getElementsByClassName("swiper-pagination-bullet");

		for (const nav of navs) {
			if (nav !== element) {
				nav.classList.remove("swiper-pagination-bullet-active");
			} else {
				nav.classList.add("swiper-pagination-bullet-active");
			}
		}
	};

	const sliderSlideSwipe = (sliderWrap, slideNum) => {
		let itemsWrap = sliderWrap.getElementsByClassName("how-it-works-home-tabs__item")[0];
		let navItem = sliderWrap.getElementsByClassName("swiper-pagination-bullet")[slideNum];

		sliderWrap.dataset.currSlideNum = slideNum;

		itemsWrap.style.cssText += "transform: translateX(" + (sliderWrap.clientWidth * slideNum) + "px);";

		let navs = sliderWrap.getElementsByClassName("swiper-pagination-bullet");

		for (const nav of navs) {
			if (nav !== navItem) {
				nav.classList.remove("swiper-pagination-bullet-active");
			} else {
				nav.classList.add("swiper-pagination-bullet-active");
			}
		}
	};

	const bannerAnimation = {
		loop: true,
		autoplay: true,
		animationData: bannerData,
		rendererSettings: {
			preserveAspectRatio: "xMidYMid slice"
		}
	};

	let touchstartX = 0;
	let touchendX = 0;

	function checkDirection(sliderWrap) {
		let itemsWrap = sliderWrap.getElementsByClassName("how-it-works-home-tabs__item")[0];
		let slideNum = sliderWrap.dataset.currSlideNum;

		if (touchendX > touchstartX && (Math.abs(touchendX - touchstartX)) > 100) {
			slideNum++;

			if (slideNum === 4) {
				slideNum = 0;
			}

			sliderWrap.dataset.currSlideNum = slideNum;

			sliderSlideSwipe(sliderWrap, slideNum);
		}

		if (touchendX < touchstartX && (Math.abs(touchendX - touchstartX)) > 100) {
			slideNum--;

			if (slideNum === -1) {
				slideNum = 3;
			}

			sliderWrap.dataset.currSlideNum = slideNum;

			sliderSlideSwipe(sliderWrap, slideNum);
		}
	}

	const sliderSwipe = (sliderWrap) => {
		document.addEventListener('touchstart', (e) => {
			touchstartX = e.changedTouches[0].screenX;
		});

		document.addEventListener('touchend', (e) => {
			touchendX = e.changedTouches[0].screenX;
			checkDirection(sliderWrap);
		});
	};

	const howSlider = () => {
		let sliderWraps = document.getElementsByClassName("how-it-works-home-tabs__body");

		const itemLenght = sliderWraps[0].clientWidth;

		console.log("itemLenght", itemLenght);

		for (const sliderWrap of sliderWraps) {
			sliderSwipe(sliderWrap);

			let items = sliderWrap.getElementsByClassName("item-how-home__step");

			let itemsWrap = sliderWrap.getElementsByClassName("how-it-works-home-tabs__item")[0];
			let navsWrap = sliderWrap.getElementsByClassName("item-how-home__pagination")[0];

			let itemsNum = items.length;

			itemsWrap.style.cssText = "width: " + (itemLenght * itemsNum) + "px;";
		}
	};

	React.useEffect(() => {
		howSlider();
		startAnimation();
	}, []);

	return (
		<>
			<head>
				<title>Home</title>
				<meta charset="UTF-8" />
				<meta name="format-detection" content="telephone=no" />
				<link rel="shortcut icon" href="favicon.ico" />
				<meta name="viewport" content="width=device-width, initial-scale=1.0" />
				<meta name="description" content="" />
				<meta name="keywords" content="" />
			</head>

			<body>
				{/* <div class="video-popup-wrapper" id="video-popup">
					<div class="close-button" onClick={() => closeVideoPopup()}></div>
					<div class="popup-close-background" onClick={() => closeVideoPopup()}></div>
					<div class="video-popup">
						<div class="video-popup-video">
							<span class="play-button" onClick={() => playHowVideo()}></span>
							<video id="optimai-video" controls="true"></video>
						</div>
					</div>
				</div> */}
				
				{openVideoPopup === true ? (
					<div class="video-popup-wrapper" id="video-popup">
						<div class="close-button" onClick={() => closeVideo()}></div>
						<div class="popup-close-background" onClick={() => closeVideo()}></div>
						<div class="video-popup">
							<div class="video-popup-video">
								<iframe width="80%" height="630" src={'https://www.youtube.com/embed/' + videoLink + '?rel=0'} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
							</div>
						</div>
					</div>
				) : ''}

				<div class="wrapper">
					<Header />
					<main class="home">
						<section class="home__main main-home">
							<div class="main-home__container">
								<div class="main-home__text-block">
									<h1 class="main-home__title">ייעוץ משכנתא חכם<br />
										דרך קלה למשכנתא מעולה</h1>
									<h2 class="main-home__subtitle">אופטימאי משיגה לכם את המשכנתא
										המשתלמת ביותר עבורכם, עם הריביות
										הנמוכות ביותר במשק.</h2>
									<div class="main-home__buttons">
										<a href="/calculator" class="main-home__button main-home__button_calculator">
											<img src="img/svg/calc.svg" alt="Calculator icon" />
											<p>למחשבון משכנתא חכם</p>
										</a>
										<a class="main-home__button main-home__button_video" onClick={() => openVideo("UGW8_hIC0eU")}> 
											<img src="img/svg/video-icon.svg" alt="Video icon" />
											<p>לצפייה בסרטון</p>
										</a>
									</div>
								</div>
								<div class="main-home__construction-decor-ibg">
									<Lottie
										className="animation"
										options={bannerAnimation}
									/>
								</div>
							</div>
						</section>
						<div class="main-home__ground-decor-ibg">
							<img src="img/home/main/ground.svg" alt="Ground" />
						</div>

						<section class="home__how-it-works how-it-works-home">
							<div class="scroll-item-wrapper">
								<div class="scroll-item" id="how-it-works"></div>
							</div>
							<div class="how-it-works-home__container how-it-works-home__container_big">
								<h2 class="how-it-works-home__title _section-title">איך זה עובד</h2>
								<div class="how-it-works-home__body">
									<div data-tabs class="how-it-works-home-tabs how-it-works-home__tabs">
										<nav data-tabs-titles class="how-it-works-home-tabs__navigation">
											<button type="button" class="how-it-works-home-tabs__title _tab-active" id="how-it-works-slider-first-title" onClick={() => openTab("how-it-works-slider-first")}>
												<p>תכנון משכנתא</p>
											</button>
											<button type="button" class="how-it-works-home-tabs__title" id="how-it-works-slider-second-title" onClick={() => openTab("how-it-works-slider-second")}>
												<p>מכרז ריביות חכם</p>
											</button>
											<button type="button" class="how-it-works-home-tabs__title" id="how-it-works-slider-third-title" onClick={() => openTab("how-it-works-slider-third")}>
												<p>קבלת משכנתא</p>
											</button>
										</nav>
										<div data-tabs-body class="how-it-works-home-tabs__content">
											<button type="button" class="how-it-works-home-tabs__title tablet _tab-active" id="how-it-works-slider-first-title" onClick={() => openTab("how-it-works-slider-first")}>
												<p>תכנון משכנתא</p>
											</button>
											<div class="how-it-works-home-tabs__body swiper active" id="how-it-works-slider-first" data-curr-slide-num="0">
												<div class="how-it-works-home-tabs__item item-how-home swiper-wrapper">
													<div class="item-how-home__step swiper-slide">
														<div class="item-how-home__number">
															<p>01</p>
														</div>
														<h3 class="item-how-home__title">איסוף נתונים</h3>
														<p class="item-how-home__describe">מעבר על הנתונים של הלווים ושל העסקה על מנת לבנות פרופיל פיננסי.
														</p>
													</div>
													<div class="item-how-home__step swiper-slide">
														<div class="item-how-home__number">
															<p>02</p>
														</div>
														<h3 class="item-how-home__title">תכנון הרכב המשכנתא</h3>
														<p class="item-how-home__describe">ביצוע סימולציה של מספר הרכבי משכנתא והתאמת ההרכב המתאים לצרכי הלווה.</p>
													</div>
													<div class="item-how-home__step swiper-slide">
														<div class="item-how-home__number">
															<p>03</p>
														</div>
														<h3 class="item-how-home__title">תחזית ריביות</h3>
														<p class="item-how-home__describe">קבלת תחזית ריביות בכל הבנקים ובחירת הבנקים המתאימים ביותר.</p>
													</div>
													<div class="item-how-home__step swiper-slide">
														<div class="item-how-home__number">
															<p>04</p>
														</div>
														<h3 class="item-how-home__title">הכנה למכרז ריביות חכם</h3>
														<p class="item-how-home__describe">הכנת רשימת מסמכים נדרשים על מנת להגיש בקשה. הכנת דף נלווה המתאר את הבקשה ואת נתוני הלווה והעסקה.</p>
													</div>
												</div>
												<div class="item-how-home__pagination_first item-how-home__pagination _slider-pagination">
													<span class="swiper-pagination-bullet swiper-pagination-bullet-active" onClick={(event) => sliderSlide(event, 0)}></span>
													<span class="swiper-pagination-bullet" onClick={(event) => sliderSlide(event, 1)}></span>
													<span class="swiper-pagination-bullet" onClick={(event) => sliderSlide(event, 2)}></span>
													<span class="swiper-pagination-bullet" onClick={(event) => sliderSlide(event, 3)}></span>
												</div>
											</div>
											<button type="button" class="how-it-works-home-tabs__title tablet" id="how-it-works-slider-second-title" onClick={() => openTab("how-it-works-slider-second")}>
												<p>מכרז ריביות חכם</p>
											</button>
											<div class="how-it-works-home-tabs__body swiper" id="how-it-works-slider-second" data-curr-slide-num="0">
												<div class="how-it-works-home-tabs__item item-how-home swiper-wrapper">
													<div class="item-how-home__step swiper-slide">
														<div class="item-how-home__number">
															<p>01</p>
														</div>
														<h3 class="item-how-home__title">פניה לבנקים</h3>
														<p class="item-how-home__describe">פניה חכמה לבנקים המתאימים על פי תכנית המשכנתא לקבלת אישורים עקרוניים.</p>
													</div>
													<div class="item-how-home__step swiper-slide">
														<div class="item-how-home__number">
															<p>02</p>
														</div>
														<h3 class="item-how-home__title">קבלת אישורים עקרוניים</h3>
														<p class="item-how-home__describe">השוואת אישורים עקרוניים באמצעות מחשבון משכנתא חכם והכנה למשא ומתן מול הבנקים.</p>
													</div>
													<div class="item-how-home__step swiper-slide">
														<div class="item-how-home__number">
															<p>03</p>
														</div>
														<h3 class="item-how-home__title">ניהול משא ומתן</h3>
														<p class="item-how-home__describe">משא ומתן חכם מול הבנקים עד לקבלת ההצעה האופטימלית על פי החישוב בתכנית המשכנתא.</p>
													</div>
													<div class="item-how-home__step swiper-slide">
														<div class="item-how-home__number">
															<p>04</p>
														</div>
														<h3 class="item-how-home__title">אישור עקרוני סופי</h3>
														<p class="item-how-home__describe">קבלת אישור עקרוני סופי הכנה לביצוע משכנתא בבנק הנבחר.</p>
													</div>
												</div>
												<div class="item-how-home__pagination_first item-how-home__pagination _slider-pagination">
													<span class="swiper-pagination-bullet swiper-pagination-bullet-active" onClick={(event) => sliderSlide(event, 0)}></span>
													<span class="swiper-pagination-bullet" onClick={(event) => sliderSlide(event, 1)}></span>
													<span class="swiper-pagination-bullet" onClick={(event) => sliderSlide(event, 2)}></span>
													<span class="swiper-pagination-bullet" onClick={(event) => sliderSlide(event, 3)}></span>
												</div>
											</div>
											<button type="button" class="how-it-works-home-tabs__title tablet" id="how-it-works-slider-third-title" onClick={() => openTab("how-it-works-slider-third")}>
												<p>קבלת משכנתא</p>
											</button>
											<div class="how-it-works-home-tabs__body swiper" id="how-it-works-slider-third" data-curr-slide-num="0">
												<div class="how-it-works-home-tabs__item how-it-works-home-tabs__item item-how-home item-how-home_last swiper-wrapper">
													<div class="item-how-home__step swiper-slide">
														<div class="item-how-home__number">
															<p>01</p>
														</div>
														<h3 class="item-how-home__title">קבלת מסמכים</h3>
														<p class="item-how-home__describe">השלמת כל המסמכים הנדרשים ותיאום מול הגורמים השונים לקבלת אישור סופי של הבנק.</p>
													</div>
													<div class="item-how-home__step swiper-slide">
														<div class="item-how-home__number">
															<p>02</p>
														</div>
														<h3 class="item-how-home__title">ליווי בתהליך אישור המשכנתא</h3>
														<p class="item-how-home__describe">מתן מענה לכל בעיה שמתעוררת מול הבנק עד לקבלת אישור סופי של המשכנתא.</p>
													</div>
													<div class="item-how-home__step swiper-slide">
														<div class="item-how-home__number">
															<p>03</p>
														</div>
														<h3 class="item-how-home__title">חתימות מול הבנק</h3>
														<p class="item-how-home__describe">תיאום יום לחתימות מול הבנק לעתים יש אפשרות לתאם בסניף מעודף על הלקוח.</p>
													</div>
													<div class="item-how-home__step swiper-slide">
														<div class="item-how-home__number">
															<p>04</p>
														</div>
														<h3 class="item-how-home__title">שחרור כספים</h3>
														<p class="item-how-home__describe">ליווי בתהליך שחרור כספים לפי השלבים הנדרשים לעסקה.</p>
													</div>
												</div>
												<div class="item-how-home__pagination_first item-how-home__pagination _slider-pagination">
													<span class="swiper-pagination-bullet swiper-pagination-bullet-active" onClick={(event) => sliderSlide(event, 0)}></span>
													<span class="swiper-pagination-bullet" onClick={(event) => sliderSlide(event, 1)}></span>
													<span class="swiper-pagination-bullet" onClick={(event) => sliderSlide(event, 2)}></span>
													<span class="swiper-pagination-bullet" onClick={(event) => sliderSlide(event, 3)}></span>
												</div>
											</div>
										</div>
										<a href="/calculator" class="how-it-works-home__calculator">
											<div class="how-it-works-home__calculator-image"><img src="img/svg/calc.svg" alt="Calculator" />
											</div>
											<p>למחשבון משכנתא חכם</p>
											<div class="how-it-works-home__arrows-image"><img src="img/svg/arrows-left.svg" alt="Arrows left" />
											</div>
										</a>
									</div>
									<div class="how-it-works-home__text-content">
										<div class="scroll-item-wrapper">
											<div class="scroll-item" id="about-container"></div>
											<img class="_decor-mobile" src="img/home/how-it-works/sky-decor_mobile.svg" alt="Sky decor" />
										</div>
										<div class="how-it-works-home__photo"><img src="img/home/how-it-works/photo.png" alt="" /></div>
										<h3 class="how-it-works-home__name">הסיפור של אופטימאי</h3>
										<p class="how-it-works-home__text">אלירן גנסיה: "גם אני לא הבנתי מאיפה כדאי לי לקחת משכנתא, רצתי מבנק לבנק
											וכשהבנתי שגם היועצים לא סגורים על עצמם, והמשחק מראש מכור לבנקים, החלטתי להרים את הכפפה. זה בידיים שלנו,
											רק אנחנו משלמים את המחיר ורק יחד אפשר לעשות חשבון צודק מול הבנקים" הכל התחיל מהמשכנתא הפרטית שלי והצורך
											הבסיסי לדעת האם ההצעה שקיבלתי מהבנק היא באמת משתלמת. מאז ועד היום בוצעו באופטימאי מעל 300,000 חישובים.
											וליוונו בשירות יעוץ משכנתא חכם מעל 1000 מבקשי משכנתא. אנחנו באופטימאי מאמינים שעל מנת לקחת משכנתא בצורה
											חכמה צריך להגיע מוכנים לתהליך כשאנחנו יודעים למה אנחנו מתחייבים ומה ההצעה המשתלמת ביותר שנוכל לקבל מהבנקים.
										</p>
									</div>
								</div>
								<div class="how-it-works-home__sky-decor-ibg">
									<img class="_main-decor" src="img/home/how-it-works/sky-decor.svg" alt="Sky decor" />
								</div>
							</div>
							<div class="how-it-works-home__cars-decor-ibg">
								<img class="_cars" src="img/home/how-it-works/cars.svg" alt="Cars" />
								<img class="_cars-mobile" src="img/home/how-it-works/cars-mobile.svg" alt="Cars" />
							</div>
						</section>

						<section class="home__clients-review clients-review-home">
							<div class="clients-review-home__container">
								<h2 class="clients-review-home__title _section-title">לקוחות אופטימאי ממליצים</h2>

								<div class="gallery-slider-wrapper">
									<div class="gallery-slider-button prev" onClick={() => SliderPrev()}></div>
									<div class="gallery-slider" id="gallery-slider">
										{sliderItems.map((slide, index) => (
											<div class="gallery-slider__item" id={"gallery-slider__item-" + (index + 1)}>
												<a onClick={() => openVideo(slide.link)} class="gallery-img">
													<img src={"/img/" + slide.img} alt="" />
													<span class="play-button"></span>
												</a>
											</div>
										))}
									</div>
									<div class="gallery-slider-button next" onClick={() => SliderNext()}></div>
								</div>

								<div class="gallery-slider__pagination slider-pagination" SetItemNum>
									{sliderPoints.map((item, index) => (
										<div onClick={() => SetItemNum(index + 1)} class={currSlide === (index + 1) ? 'slider-bullets slider-bullets--active' : 'slider-bullets'}></div>
									))}
								</div>

							</div>
						</section>
					</main>
					<Footer />
				</div>
			</body>
		</>
	);
};
