import React from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { LinkCss } from '../services/cssService';
import { Footer } from './footer';
import { Header } from './header';
import { Get, Post, Serialize } from '../services/http';

export const ContactUs = () => {
	const [formObject, setFormObject] = React.useState({ loanUrgency: "", firstName: "", phone: "", email: "", message: "" });
	const { id } = useParams();

	React.useEffect(() => {
		LinkCss("/css/homepage-style.css");
	}, []);

	function validateEmail($email) {
		let emailReg = /^([\w-.]+@([\w-]+\.)+[\w-]{2,4})?$/;
		if (emailReg.test($email) && $email !== "") {
			return true;
		}
		return false;
	}

	function validetePhone($phone) {
		let phoneReg = /^0(5[^7]|[2-4]|[8-9]|7[0-9])[0-9]{7}$/;
		if (phoneReg.test($phone) && $phone !== "") {
			return true;
		}
		return false;
	}

	const valideteEmpty = (value) => {
		if (value === undefined) {
			return false;
		} else if (value.length < 2) {
			return false;
		} else {
			return true;
		}
	};

	React.useEffect(() => {
		console.log("formObject", formObject);
	}, [formObject]);

	const readSelectValue = (identificator) => {
		const formObjectSave = {};
		const element = document.getElementById(identificator);
		const inputValue = element.value;
		formObjectSave[identificator] = inputValue;
		let newFormObject = {
			...formObject,
			...formObjectSave
		};
		setFormObject(newFormObject);
	};

	const readInputValue = (identificator) => {
		const formObjectSave = {};
		const element = document.getElementById(identificator);
		const elementWrap = element.closest(".form-input-wrapper");
		const inputValue = element.value;
		formObjectSave[identificator] = inputValue;
		let newFormObject = {
			...formObject,
			...formObjectSave
		};
		setFormObject(newFormObject); 

		if (!valideteEmpty(inputValue)) {
			elementWrap.classList.add("notValid");
		} else {
			elementWrap.classList.remove("notValid");
		}

		if (identificator === 'email') {
			if (!validateEmail(inputValue) && valideteEmpty(inputValue)) {
				elementWrap.classList.add("notValid");
				elementWrap.classList.add("currValid");
			} else if (validateEmail(inputValue)) {
				elementWrap.classList.remove("notValid");
				elementWrap.classList.remove("currValid");
			}
		}

		if (identificator === 'phone') {
			if (!validetePhone(inputValue) && valideteEmpty(inputValue)) {
				elementWrap.classList.add("notValid");
				elementWrap.classList.add("currValid");
			} else if (validetePhone(inputValue)) {
				elementWrap.classList.remove("notValid");
				elementWrap.classList.remove("currValid");
			}
		}
	};

	const cleanForm = () => {

	};

	const sendForm = () => {
		const inputs = document.querySelectorAll('.form-input-wrapper input');

		for (const input of inputs) {
			const elementWrap = input.closest(".form-input-wrapper");

			if (!valideteEmpty(input.value)) {
				elementWrap.classList.add("notValid");
			}
		}

		if (formObject['mortgage'] === "") {
			const element = document.querySelectorAll('.form-radio-wrapper');
			element[0].classList.add("notValid");
		}

		if (formObject['terms'] === false || formObject['terms'] === "") {
			const element = document.querySelectorAll('.form-checkbox-wrapper');
			element[0].classList.add("notValid");
		}

		if (valideteEmpty(formObject['firstName']) && formObject['loanUrgency'] !== "" && validetePhone(formObject['phone']) && validateEmail(formObject['email']) && valideteEmpty(formObject['message'])) {
			const sendObject = {
				...formObject,
				action: 'contact_us_lead'
			};

			const params = Serialize(sendObject);

			axios.get('https://optimai.co.il/wp-admin/admin-ajax.php?' + params).then(function (response) {
				alert("ההודעה נשלחה");
				document.location.reload(true);
			});
		}
	};

	return (
		<>
			<head>
				<title>Contact us</title>
				<meta charset="UTF-8" />
				<meta name="format-detection" content="telephone=no" />
				<link rel="shortcut icon" href="favicon.ico" />
				<meta name="viewport" content="width=device-width, initial-scale=1.0" />
				<meta name="description" content="" />
				<meta name="keywords" content="" />
			</head>

			<body>
				<div class="wrapper">
					<Header />
					<main class="contact-us">
						<section class="contact-us__main">
							<div class="contact-us__container">
								<h1 class="contact-us__title">בטוח יש לך מלא שאלות, התלבטיות ובקשות. פשוט דברו איתנו! </h1>
								<h2 class="contact-us__subtitle">שלב בתהליך</h2>
								<div class="contact-us__body">
									<div class="contact-us__form-block">
										<form action="#" class="contact-us__form contact-us-form">
											<div class="contact-us-form__inputs-block">
												<div class="contact-us-form__inputs">
													<div class="contact-us-form__select-block">
														<div class="contact-us-form__select-arrow"><img src="img/contact-us/arrow-down.svg" alt="Arrow down" /></div>
														<select onChange={() => readSelectValue('loanUrgency')} id="loanUrgency" class="contact-us-form__select">
															<option value="" class="_placeholder">שלב בתהליך</option>
															<option value="1">בירור ראשוני</option>
															<option value="2">מחפש נכס</option>
															<option value="3">נחתם חוזה</option>
															<option value="4">שולם חלק מהסכום</option>
															<option value="5">אחר</option>
														</select>
													</div>
													<div class="form-input-wrapper inline-block">
														<div class="from-input-warning">חובה לסמן!</div>
														<input onChange={() => readInputValue('firstName')} id="firstName" type="text" placeholder="שם מלא" class="contact-us-form__input" />
													</div>
													<div class="form-input-wrapper inline-block">
														<div class="from-input-warning">חובה לסמן!</div>
														<div class="from-input-warning currValid">הזן מספר טלפון חוקי!</div>
														<input onChange={() => readInputValue('phone')} id="phone" type="number" placeholder="מספר טלפון" class="contact-us-form__input" inputmode="numeric" />
													</div>
													<div class="form-input-wrapper inline-block">
														<div class="from-input-warning">חובה לסמן!</div>
														<div class="from-input-warning currValid">הזן כתובת אמייל חוקית!</div>
														<input onChange={() => readInputValue('email')} id="email" type="email" name="email" placeholder="כתובת דוא״ל" class="contact-us-form__input" />
													</div>
												</div>
												<div class="contact-us-form__textarea-block">
													<div class="form-input-wrapper inline-block">
														<div class="from-input-warning">חובה לסמן!</div>
														<textarea onChange={() => readInputValue('message')} id="message" placeholder=" פרט לנו באיזה נושא תרצה עזרה :" class="contact-us-form__input contact-us-form__textarea"></textarea>
													</div>
												</div>
											</div>
											<button onClick={() => sendForm()} type="submit" class="contact-us-form__button">
												<p>שלח</p>
											</button>
										</form>
									</div>
									<div class="contact-us__contacts-block">
										<div class="contact-us__contacts-item">
											<h2 class="contact-us__contacts-item-title">משרדים</h2>
											<p class="contact-us__contacts-info">ראש העין רחוב העבודה 7 בניין מליחי טלפון : 03-5052400</p>
										</div>
										<div class="contact-us__contacts-item">
											<h2 class="contact-us__contacts-item-title">שעות פעילות</h2>
											<div class="contact-us__contacts-info-block">
												<p class="contact-us__contacts-info">ראשון - חמישי: <br />9:00 - 17:00</p>
												<p class="contact-us__contacts-info">שישי וערבי חג<br />9:00 - 12:00</p>
											</div>
										</div>
									</div>
								</div>
								<div class="contact-us__construction-decor-ibg"><img src="img/contact-us/construction-decor.svg" alt="Construction" /></div>
								<div class="contact-us__clouds-decor-ibg">
									<img class="_clouds" src="img/contact-us/clouds.svg" alt="Clouds" />
									<img class="_clouds-mobile" src="img/contact-us/clouds_mobile.svg" alt="Clouds" />
								</div>
							</div>
						</section>
					</main>
					<Footer />
				</div>
			</body>
		</>
	);
};
